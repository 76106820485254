export default () => ({
  data: {
    code: "",
    email: "",
    consent: false,
  },
  formLoading: false,
  formMessage: "",
  selected: JSON.parse(document.querySelector("#regionTitle").textContent),
  buttonText: "Submit",
  submit(
    data,
    code,
    successMessage = "Merci ! Votre inscription a bien été prise en compte.",
  ) {
    this.formLoading = false;
    if (code) {
      data.code = code;
    }
    const body = JSON.stringify(data);
    fetch(`/form/newsletter/?data=${encodeURIComponent(body)}`, {
      method: "GET",
      mode: "same-origin",
    })
      .then(() => {
        this.data.consent = false;
        this.formMessage = `<svg class="relative" style="top: 5px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="fill-current" d="M19.875 8.325C19.925 8.60833 19.9583 8.88733 19.975 9.162C19.9917 9.43733 20 9.71667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C10.85 0 11.671 0.104 12.463 0.312C13.2543 0.520667 14 0.808333 14.7 1.175C14.95 1.30833 15.1043 1.51233 15.163 1.787C15.221 2.06233 15.1583 2.31667 14.975 2.55C14.8083 2.76667 14.5917 2.90833 14.325 2.975C14.0583 3.04167 13.8083 3.00833 13.575 2.875C13.0417 2.59167 12.471 2.375 11.863 2.225C11.2543 2.075 10.6333 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 9.78333 17.9917 9.57067 17.975 9.362C17.9583 9.154 17.9333 8.94167 17.9 8.725C17.8667 8.49167 17.9043 8.275 18.013 8.075C18.121 7.875 18.275 7.71667 18.475 7.6C18.7583 7.43333 19.046 7.429 19.338 7.587C19.6293 7.74567 19.8083 7.99167 19.875 8.325ZM7.9 13.9L5.025 11.025C4.84167 10.8417 4.75433 10.6123 4.763 10.337C4.771 10.0623 4.86667 9.83333 5.05 9.65C5.23333 9.46667 5.46667 9.375 5.75 9.375C6.03333 9.375 6.26667 9.46667 6.45 9.65L8.6 11.8L17.925 2.45C18.1083 2.26667 18.3377 2.179 18.613 2.187C18.8877 2.19567 19.1167 2.29167 19.3 2.475C19.4833 2.65833 19.575 2.89167 19.575 3.175C19.575 3.45833 19.4833 3.69167 19.3 3.875L9.3 13.9C9.11667 14.0833 8.88333 14.175 8.6 14.175C8.31667 14.175 8.08333 14.0833 7.9 13.9Z" fill="white"/>
</svg> ${successMessage}`;
      })
      .catch(() => {
        this.formMessage =
          "Une erreur est survenue, merci de réessayer la soumission du formulaire.";
      })
      .finally(() => {
        this.formLoading = false;
      });
  },
});
