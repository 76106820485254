import Swiper from "swiper";
import { Navigation } from "swiper/modules";

class Slider {
  static selector() {
    return ".swiper";
  }

  constructor(node) {
    this.node = node;
    this.init();
  }

  init() {
    this.slider = new Swiper(this.node, {
      slidesPerView: "auto",
      scrollbar: false,
      spaceBetween: 20,
      setWrapperSize: true,
      slideOffsetBefore: 20,
      slidesOffsetAfter: 20,
      centeredSlides: true,
      centeredSlidesBounds: true,
      modules: [Navigation],
      breakpoints: {
        768: {
          spaceBetween: 32,
          centeredSlides: false,
        },
      },
      navigation: {
        nextEl: ".slider-button-next",
        prevEl: ".slider-button-prev",
      },
    });
  }
}

export default Slider;
