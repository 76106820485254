import maplibregl, { MapOptions } from "maplibre-gl";

type Farm = {
  latitude: string;
  longitude: string;
};

class Map {
  static selector() {
    return "#map-hub";
  }
  node: HTMLElement;
  style: string;
  mapData: Farm[];
  latitude: string;
  longitude: string;

  constructor(node) {
    this.node = node;
    this.style = "https://cartes.terredeliens.org/styles/tdl/style.json";
    this.mapData = JSON.parse(document.getElementById("mapData").textContent);
    this.init();
  }

  init() {
    const latitude = JSON.parse(
      document.getElementById("latitude")?.textContent || null,
    );
    const longitude = JSON.parse(
      document.getElementById("longitude")?.textContent || null,
    );
    const clustered = !latitude && !longitude;
    const mapConfig: MapOptions = {
      container: this.node,
      style: this.style,
      zoom: 5,
      minZoom: 5,
      maxZoom: 11,
      center: [2.2137, 46.2276],
    };

    if (window.visualViewport.width < 768) {
      mapConfig.zoom = 4.1;
      mapConfig.minZoom = 4.1;
    }

    const map = new maplibregl.Map(mapConfig);

    const features = [];

    this.mapData.forEach(function (farm) {
      features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [farm.longitude, farm.latitude],
        },
        properties: farm,
      });
    });

    map.on("load", function () {
      if (latitude && longitude) {
        map.flyTo({
          center: [longitude, latitude],
          zoom: 6.5,
        });
      }
      map.addSource("farms", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features,
        },
        cluster: clustered,
        clusterMaxZoom: 6,
        clusterRadius: 40,
        clusterMinPoints: 10,
      });

      if (clustered) {
        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "farms",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://maplibre.org/maplibre-gl-js-docs/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": "#E3CFB5",
            "circle-radius": [
              "step",
              ["get", "point_count"],
              15,
              3,
              20,
              10,
              30,
            ],
          },
        });
        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "farms",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Roboto Regular"],
            "text-size": 14,
          },
        });

        map.on("click", "clusters", function (e) {
          var features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          var clusterId = features[0].properties.cluster_id;
          map
            .getSource("farms")
            .getClusterExpansionZoom(clusterId, function (err, zoom) {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });
      }

      map.addLayer({
        id: "farms",
        type: "circle",
        source: "farms",
        filter: clustered ? ["!", ["has", "point_count"]] : true,
        paint: {
          "circle-radius": 8,
          "circle-color": ["get", "color"],
        },
      });

      map.on("click", "farms", function (e) {
        e.originalEvent.preventDefault();

        const id = e.features[0].properties.id;
        fetch("/api/v2/pages/" + id)
          .then((response) => response.json())
          .then((data) => {
            const html = data.map_popup_display;
            new maplibregl.Popup().setLngLat([0, 0]).setHTML(html).addTo(map);
          });
      });

      // Change the cursor to a pointer when the mouse is over the farms layer.
      map.on("mouseenter", "farms", function () {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "farms", function () {
        map.getCanvas().style.cursor = "";
      });

      // Navigation
      const nav = new maplibregl.NavigationControl({ showCompass: false });
      map.addControl(nav, "bottom-left");

      // Désactivation du zoom au scroll sur la page
      map.scrollZoom.disable();
    });
  }
}

export default Map;
