class ExternalLink {
  static selector() {
    return "a[href]";
  }

  constructor(node) {
    this.node = node;
    this.domain = new URL(`https://${document.body.dataset.domain}`).hostname;
    this.checkIfIsExternal();
  }

  checkIfIsExternal() {
    try {
      new URL(this.node.href);
    } finally {
      if (!new URL(this.node.href).host.includes(this.domain)) {
        this.node.target = "_blank";
        this.node.rel = "noreferrer nofollow noopener";
      }
    }
  }
}

export default ExternalLink;
