export default () => ({
  visible: false,
  html: null,
  id: null,
  open(detail) {
    this.html = detail.html;
    this.id = detail.id;

    if (!localStorage.getItem(this.id)) {
      this.visible = true;
    }
  },
  close() {
    if (this.id) {
      localStorage.setItem(this.id, true);
    }
    this.visible = false;
    this.html = null;
  },
});
