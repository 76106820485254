import "vite/modulepreload-polyfill";

import "maplibre-gl/dist/maplibre-gl.css";
import "swiper/css";
import "swiper/css/navigation";
import "../css/main.css";

import "@appnest/masonry-layout";
import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";

import App from "./components/app";
import ExternalLink from "./components/external-link";
import Slider from "./components/slider";
import Map from "./components/map";
import Hub from "./components/hub";
import Modal from "./components/modal";
import Media from "./components/media";
import Newsletter from "./components/newsletter";

Alpine.plugin(Clipboard);

document.addEventListener("alpine:init", () => {
  Alpine.data("modal", Modal);
  Alpine.data("embed", Media);
  Alpine.data("newsletter", Newsletter);
});

window.Alpine = Alpine;

Alpine.start();

App(() => {
  for (const externalLink of document.querySelectorAll(
    ExternalLink.selector(),
  )) {
    new ExternalLink(externalLink);
  }

  for (const map of document.querySelectorAll(Map.selector())) {
    new Map(map);
  }

  for (const hub of document.querySelectorAll(Hub.selector())) {
    new Hub(hub);
  }

  for (const slider of document.querySelectorAll(Slider.selector())) {
    new Slider(slider);
  }
});
